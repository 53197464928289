// Tiny helper to escape switches/ternary for environment-specific configs.
const forEnvironment = config => config[process.env.GATSBY_ENVIRONMENT] || config.default;

export const APP_ID = forEnvironment({
  production: 'P6VZMQO',
  default: 'PITQR9U'
});

export const HOST = forEnvironment({
  production: 'pagerduty.com',
  default: 'pd-staging.com'
});

export const DEMO_APP_URL = forEnvironment({
  production: 'https://acme.pagerduty.dev',
  staging: 'https://integration-demo-app.pd-staging.com/',
  default: 'http://localhost:8000'
});

export const DEFAULT_BASE_URL = forEnvironment({
  production: 'https://events.pagerduty.com/v2/enqueue',
  default: 'https://events.pd-staging.com/v2/enqueue'
});

// Only send notification in production
export const PAYLOAD_SOURCE = 'monitoringtool:cloudvendor:central-region-dc-01:852559987:cluster/api-stats-prod-003';
