import React, { useState } from 'react';

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Helmet from 'react-helmet';
import Cookies from 'js-cookie';
import { Alert } from 'reactstrap';
import axios from 'axios';

import {
  DEMO_APP_URL, HOST, DEFAULT_BASE_URL, PAYLOAD_SOURCE
} from '../config';

import PDIcon from '../images/pd-icon.svg';

axios.defaults.baseURL = DEFAULT_BASE_URL;
axios.defaults.headers.common.Accept = 'application/vnd.pagerduty+json;version=2';

const SettingsPage = (values) => {
  const accountName = typeof (values.accountSubdomain) !== 'undefined' ? values.accountSubdomain.subdomain : '';
  const services = typeof (values.serviceList) !== 'undefined' ? values.serviceList : '';
  const triggeredNewIncidentText = 'Triggered a new incident in PagerDuty for service: ';
  const failedToTriggerNewIncidentText = 'Failed to trigger a new incident in PagerDuty for service: ';
  const [failedToTriggerNewIncident, setFailedToTriggerNewIncident] = useState(false);
  const [selectedService, setSelectedService] = useState({});
  const [isTriggered, setIsTriggered] = useState(false);

  const onDisconnect = () => {
    if (typeof window !== 'undefined') {
      Cookies.remove('integrationParams');
      window.location.href = DEMO_APP_URL;
    }
  };

  const createIncident = (service) => {
    setSelectedService(service);
    setIsTriggered(true);
    setFailedToTriggerNewIncident(false);
    axios.post('/', {
      routing_key: `${service.integration_key}`,
      event_action: 'trigger',
      images: [],
      links: [],
      payload: {
        summary: `Example alert on ${service.name}`,
        source: PAYLOAD_SOURCE,
        severity: 'info'
      }
    })
      .then(() => {
        setFailedToTriggerNewIncident(false);
      })
      .catch(() => {
        setFailedToTriggerNewIncident(true);
      });
  };

  return (
    <div className="connect">
      <div className="row">
        {
            failedToTriggerNewIncident && isTriggered && (
            <Alert color="danger" className="col-md-12">
              {failedToTriggerNewIncidentText + selectedService.name}
            </Alert>
            )
          }
        {
            !failedToTriggerNewIncident && isTriggered && (
            <Alert className="col-md-12">
              {triggeredNewIncidentText + selectedService.name}
            </Alert>
            )
          }
        <Helmet>
          <style>
            {`
            .card {
              border-bottom: 1px solid rgba(0,0,0,.125) !important;
              margin-bottom: 0.5rem;
            }
          `}
          </style>
        </Helmet>
        <div className="col col-lg-2">
          <img src={PDIcon} alt="PD Logo" />
        </div>
        <div className="col col-lg-10">
          <div className="settings-title pd-side">PagerDuty Settings</div>
            You can manage alerts with your PagerDuty account.
          <div className="account mb-2">
            <b>Account:</b>
            <br />
            <a id="account-subdomain" href={`https://${accountName}.${HOST}`}>
              {accountName}
.
              {HOST}
            </a>
          </div>
          {services !== '' && (
            services.map(service => (
              <Accordion key={service.name}>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                      <b>Service Name:</b>
                      {' '}
                      {service.name}
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <div className="row fs-sm mb-1">
                        <div className="col-9 ml-1">
                          <b>Integration Key</b>
                          <p>{service.integration_key}</p>
                        </div>
                        <div className="col text-right">
                          <Button type="button" variant="danger" size="md" onClick={() => createIncident(service)}>Trigger</Button>
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            )))}
          <div>
            <div className="col mt-4 text-right">
              <Button
                id="disconnect_button"
                variant="outline-secondary"
                size="md"
                onClick={onDisconnect}
              >
                  Disconnect Account
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
